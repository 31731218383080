import { SubstitutionValue } from 'shared/src/features/substitution/substitution.types';
import { WidgetId } from 'shared/src/features/widgets/widgets.types';
import { createMachine, assign } from 'xstate';

import {
  initSessionIfNeeded,
  sendLeadData,
  setCookiesSessionLead,
} from './PublicDemoPage.utils';
import {
  NormalizedPublishedFlows,
  NormalizedPublishedProject,
  NormalizedPublishedWidgets,
} from './api/project.api';
import { PublishedFormConfig } from './LeadForm/LeadForm.types';
import { PublicDemoLead } from './PublicDemoPage.types';

export const PASSCODE_STORAGE_PREFIX = 'passcode_';

export const REQUIRED_EMAIL_STORAGE_PREFIX = 'required-email_';

export const REQUIRED_EMAIL_CODE_STORAGE_PREFIX = 'required-email-code_';

export const EVENT_TYPE = {
  ResolveProject: 'RESOLVE_PROJECT',
  LeadCreated: 'LEAD_CREATED',
  LastWidgetVisited: 'LAST_WIDGET_CHANGE',
  FinishWalkthrough: 'FINISH_WALKTHROUGH',
} as const;

type MachineEventType =
  | {
      type: typeof EVENT_TYPE.ResolveProject;
      sessionLead: PublicDemoLead | null;
      queryLead: PublicDemoLead | null;
      project: NormalizedPublishedProject;
      flows: NormalizedPublishedFlows;
      widgets: NormalizedPublishedWidgets;
      substitutions: SubstitutionValue[];
      leadFormConfig?: PublishedFormConfig;
    }
  | { type: typeof EVENT_TYPE.LeadCreated; lead: PublicDemoLead }
  | { type: typeof EVENT_TYPE.LastWidgetVisited; widgetId: WidgetId }
  | { type: typeof EVENT_TYPE.FinishWalkthrough };

export interface MachineContext {
  demoId: string;
  leadFormConfig: PublishedFormConfig | null;
  /**
   * `isKnownLead` means BE analytics request for is was sent in any session.
   * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/393227/Public+Demo#Lead-in-Session
   */
  isKnownLead: boolean;
  lead: PublicDemoLead | null;
  project: NormalizedPublishedProject;
  widgets: NormalizedPublishedWidgets | null;
  flows: NormalizedPublishedFlows | null;
  substitutions: SubstitutionValue[];
  flags: {
    forceHideForms: boolean;
  };
  baseUrl: string;
}

export enum PublicDemoMachineStateValue {
  ProjectResolved = 'projectResolved',
  LeadFormShown = 'leadFormShown',
  Playback = 'playback',
  LeadCapture = 'leadCapture',
  WalkthroughFinished = 'walkthroughFinished',
}

type MachineTypeState =
  | {
      value: PublicDemoMachineStateValue.ProjectResolved;
      context: MachineContext & {
        project: NormalizedPublishedProject;
        widgets: NormalizedPublishedWidgets;
        flows: NormalizedPublishedFlows;
        leadFormConfig: PublishedFormConfig | null;
      };
    }
  | {
      value: PublicDemoMachineStateValue.LeadCapture;
      context: MachineContext & {
        project: NormalizedPublishedProject;
        leadFormConfig: PublishedFormConfig;
      };
    }
  | {
      value: PublicDemoMachineStateValue.LeadFormShown;
      context: MachineContext & {
        project: NormalizedPublishedProject;
        widgets: NormalizedPublishedWidgets;
        flows: NormalizedPublishedFlows;
        leadFormConfig: PublishedFormConfig;
      };
    }
  | {
      value: PublicDemoMachineStateValue.Playback;
      context: MachineContext & {
        project: NormalizedPublishedProject;
        widgets: NormalizedPublishedWidgets;
        flows: NormalizedPublishedFlows;
        leadFormConfig: PublishedFormConfig | null;
      };
    }
  | {
      value: PublicDemoMachineStateValue.WalkthroughFinished;
      context: MachineContext & {
        project: NormalizedPublishedProject;
        leadFormConfig: PublishedFormConfig | null;
      };
    };

// guards -- start
const isLeadFormRequired = (
  context: MachineContext
): context is MachineContext & {
  leadFormConfig: PublishedFormConfig;
} => {
  return (
    !context.isKnownLead &&
    !context.flags.forceHideForms &&
    Boolean(context.leadFormConfig)
  );
};
const isUnknownLeadPresented = (
  context: MachineContext
): context is MachineContext & { lead: PublicDemoLead } => {
  // TODO: add docs to explain lead creation on demoplayer start
  return (
    !context.isKnownLead &&
    (Boolean(context.lead?.email) || Boolean(context.lead?.externalId))
  );
};
const isBeginLeadFormRequired = (context: MachineContext) =>
  isLeadFormRequired(context) && context.leadFormConfig.position === 'begin';
const isEndLeadFormRequired = (context: MachineContext) =>
  isLeadFormRequired(context) && context.leadFormConfig.position === 'end';
// guards -- finish

export const publicDemoMachine = createMachine<
  MachineContext,
  MachineEventType,
  MachineTypeState
>(
  {
    id: 'publicDemo',
    initial: 'projectResolved',
    states: {
      projectResolved: {
        entry: ['initSession'],
        always: [
          {
            target: 'playback',
            cond: 'isUnknownLeadPresented',
            actions: ['onLeadCreatedAnalytics'],
          },
          { target: 'leadFormShown', cond: 'isBeginLeadFormRequired' },
          { target: 'playback' },
        ],
      },
      leadFormShown: {
        on: {
          [EVENT_TYPE.LeadCreated]: {
            target: 'playback',
            actions: [
              assign((context, event) => ({
                isLeadCaptured: true,
                lead: event.lead,
              })),
              'onLeadCreatedAnalytics',
            ],
          },
        },
      },
      playback: {
        on: {
          [EVENT_TYPE.FinishWalkthrough]: {
            target: 'walkthroughFinished',
          },
          [EVENT_TYPE.LeadCreated]: {
            actions: [
              assign((context, event) => ({
                isLeadCaptured: true,
                lead: event.lead,
              })),
              'onLeadCreatedAnalytics',
            ],
          },
        },
      },
      walkthroughFinished: {
        always: [
          { target: 'leadFormShown', cond: 'isEndLeadFormRequired' },
          { target: 'playback' },
        ],
      },
    },
  },
  {
    guards: {
      isBeginLeadFormRequired,
      isEndLeadFormRequired,
      isUnknownLeadPresented,
    },
    actions: {
      initSession: (context) => {
        initSessionIfNeeded(context.demoId);
      },
      onLeadCreatedAnalytics: (context) => {
        if (context.project && context.lead) {
          const {
            demoId,
            project: { id: projectId, company_id: companyId },
            lead,
            baseUrl,
          } = context;
          sendLeadData({
            leadData: lead,
            projectId,
            demoId,
            companyId,
            onLeadCreate: () => setCookiesSessionLead(lead, demoId),
            baseUrl,
          });
          return {
            isKnownLead: true,
          };
        }
      },
    },
  }
);
