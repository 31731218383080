import {
  HotspotWidget,
  LeadCaptureWidget,
  PopupWidget,
  TooltipWidget,
  MediaWidget,
  VendorFormWidget,
} from './widgets.types';

export const WIDGET_VERSION = 1; // relevant widget version
export const DEFAULT_HOTSPOT_COLOR = '#9939EB';
export const DEFAULT_WIDGET_BACKGROUND_COLOR = '#FFF';
export const DEFAULT_WIDGET_BORDER_COLOR = '#807f93';
export const DEFAULT_TEXT_COLOR = '#1a1348';
export const DEFAULT_TEXT_COLOR_INVERTED = '#9392A3';

export const DEFAULT_SPOTLIGHT_COLOR = '#9939EB';

export const DEFAULT_WIDGET_PLACEHOLDER =
  'Explain the benefit of this guide...';
export const DEFAULT_TOOLTIP_WIDGET_DESCRIPTION =
  'Click to add your text here. Explain the benefit of this guide...';
export const DEFAULT_HOTSPOT_WIDGET_DESCRIPTION =
  '(optional) Explain the benefit of this guide...';
export const DEFAULT_WIDGET_BUTTON_PLACEHOLDER = 'Click to add your text here';

/**
 * Read about widget width
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/6881281/Widget+width Docs}
 */
export const DEFAULT_WIDGET_WIDTH = 250;
export const ARROW_WIDGET_MIN_WIDTH = 210;
export const ARROW_WIDGET_MAX_WIDTH = 600;

export const DEFAULT_POPUP_WIDGET_WIDTH = 340;
export const POPUP_WIDGET_MIN_WIDTH = 260;
export const POPUP_WIDGET_MAX_WIDTH = 800;
export const MEDIA_WIDGET_WIDTH = 500;
export const MEDIA_WIDGET_MAX_WIDTH = 800;
export const MEDIA_WIDGET_MIN_WIDTH = 300;
export const DEFAULT_ANCHOR_WIDGET_ZOOM = 1.2;

export const DEFAULT_BACKDROP_COLOR = 'rgba(0, 0, 0, 0.4)';

export const WidgetNeedCalcAlignment = 'needCalc';

export enum WidgetAlignPosition {
  TopLeft = 'tl',
  TopCenter = 'tc',
  TopRight = 'tr',

  RightTop = 'rt',
  RightCenter = 'rc',
  RightBottom = 'rb',

  BottomRight = 'br',
  BottomCenter = 'bc',
  BottomLeft = 'bl',

  LeftBottom = 'lb',
  LeftCenter = 'lc',
  LeftTop = 'lt',
}

export enum WidgetStatus {
  Inactive = 'inactive',
  Active = 'active',
}

export enum WidgetBackdrop {
  Enabled = 'hard',
  Disabled = 'none',
}

export enum WidgetTheme {
  Dark = 'dark',
  Light = 'light',
}

export enum WidgetKind {
  Media = 'media',
  Popup = 'popup',
  Tooltip = 'tooltip',
  Hotspot = 'hotspot',
  LeadCapture = 'lead_capture',
  VendorForm = 'vendor_form',
  VideoClip = 'video_clip',
  /**
   * @deprecated Never being used
   */
  Overlay = 'overlay',
  /**
   * @deprecated Never being used
   */
  RecordVideo = 'record',
  /**
   * @deprecated Use Media instead
   * @see WidgetKind.Media
   */
  Modal = 'modal',
  /**
   * @deprecated Use Media instead
   * @see WidgetKind.Media
   */
  Video = 'video',
}

export enum WidgetTriggerIfType {
  Click = 'click',
  InputText = 'input_text',
}

export enum WidgetTriggerThenType {
  NextWidget = 'next_widget',
  ExternalUrl = 'external_url',
}

export const TOOLTIP_WIDGET_TEMPLATE: Omit<
  TooltipWidget,
  | 'id'
  | 'projectId'
  | 'flowId'
  | 'name'
  | 'localSequence'
  | 'sequence'
  | 'status'
  | 'dom_id'
  | 'trigger'
  | 'page_id'
  | 'target_id'
  | 'target_type'
  | 'stream_url'
  | 'stream_status'
  | 'thumbnail_url'
  | 'custom_code'
> = {
  kind: WidgetKind.Tooltip,
  cta: 'Next',
  audio_url: '',
  options: {
    root: {
      alignment: WidgetAlignPosition.TopLeft,
      theme: WidgetTheme.Light,
      close_button: false,
      next_button: true,
      prev_button: false,
      secondaryButton: false,
      show_step_number: false,
      hide_arrow: false,
      backgroundColor: DEFAULT_WIDGET_BACKGROUND_COLOR,
      borderColor: DEFAULT_WIDGET_BORDER_COLOR,
      frameWidth: null,
      maxFrameWidth: null,
      backdropRect: null,
      backdropSelector: null,
      offset: { x: 0, y: 0 },
      offsetFromOriginPx: { x: 0, y: 0 },
      spot: WidgetBackdrop.Enabled,
      hasSpotlight: false,
      spotlightColor: '#9939eb',
      trigger: null,
      zoom: null,
    },
    description: {
      value: `<p>${DEFAULT_TOOLTIP_WIDGET_DESCRIPTION}</p>`,
    },
    cta: {
      bold: true,
      fontSize: 14,
      italic: false,
      textUnderline: false,
      color: '#ffffff',
      position: 'right',
      backgroundColor: {
        alpha: 100,
        hex: '#9939eb',
      },
      borderColor: '',
    },
    secondary: {
      value: 'CTA',
      bold: true,
      fontSize: 14,
      italic: false,
      textUnderline: false,
      color: '#ffffff',
      position: 'right',
      backgroundColor: {
        alpha: 100,
        hex: '#9939eb',
      },
      borderColor: '',
    },
    version: WIDGET_VERSION,
  },
};

export const MEDIA_WIDGET_TEMPLATE: Omit<
  MediaWidget,
  | 'id'
  | 'name'
  | 'sequence'
  | 'localSequence'
  | 'status'
  | 'page_id'
  | 'projectId'
  | 'flowId'
  | 'stream_url'
  | 'stream_status'
  | 'thumbnail_url'
  | 'custom_code'
> = {
  kind: WidgetKind.Media,
  video_url: '',
  image_url: '',
  audio_url: '',
  cta: 'Done',
  options: {
    root: {
      theme: WidgetTheme.Light,
      close_button: false,
      prev_button: false,
      show_step_number: true,
      videoFrameWidth: 460,
      frameHeight: 0, // TODO: what value should it be?
      frameWidth: MEDIA_WIDGET_WIDTH,
      backgroundColor: DEFAULT_WIDGET_BACKGROUND_COLOR,
      borderColor: DEFAULT_WIDGET_BORDER_COLOR,
      videoBackgroundColor: '#dedee3',
      secondaryButton: false,
    },
    description: {
      value:
        '<h2 style="text-align: center;"><strong>Your title here</strong></h2><p style="text-align: center;">Explain the benefit here and how it relates to your demo...</p>',
    },
    cta: {
      bold: true,
      fontSize: 16,
      italic: false,
      textUnderline: false,
      color: '#ffffff',
      position: 'center',
      backgroundColor: {
        alpha: 100,
        hex: '#9939eb',
      },
      borderColor: '',
    },
    secondary: {
      value: 'CTA',
      bold: true,
      fontSize: 16,
      italic: false,
      textUnderline: false,
      color: '#ffffff',
      position: 'right',
      backgroundColor: {
        alpha: 100,
        hex: '#9939eb',
      },
      borderColor: '',
    },
    image: {
      size: 100,
      actualSize: false,
      hidden: false,
    },
    version: WIDGET_VERSION,
  },
};

export const HOTSPOT_WIDGET_TEMPLATE: Omit<
  HotspotWidget,
  | 'id'
  | 'projectId'
  | 'flowId'
  | 'name'
  | 'localSequence'
  | 'sequence'
  | 'status'
  | 'dom_id'
  | 'trigger'
  | 'page_id'
  | 'target_id'
  | 'target_type'
  | 'stream_url'
  | 'stream_status'
  | 'thumbnail_url'
  | 'custom_code'
> = {
  kind: WidgetKind.Hotspot,
  cta: 'Next',
  audio_url: '',
  options: {
    root: {
      // common with `tooltip`
      alignment: WidgetAlignPosition.RightCenter,
      theme: WidgetTheme.Light,
      close_button: false,
      next_button: false,
      prev_button: false,
      secondaryButton: false,
      show_step_number: false,
      hide_arrow: false,
      backgroundColor: DEFAULT_WIDGET_BACKGROUND_COLOR,
      borderColor: DEFAULT_WIDGET_BORDER_COLOR,
      frameWidth: null,
      maxFrameWidth: null,
      backdropRect: null,
      backdropSelector: null,
      offset: { x: 0, y: 0 },
      offsetFromOriginPx: { x: 0, y: 0 },
      spot: WidgetBackdrop.Disabled,
      hasSpotlight: false,
      spotlightColor: '#9939eb',
      trigger: null,
      // unique from `tooltip`
      auto_hide_text: true,
      beaconColor: DEFAULT_HOTSPOT_COLOR,
      beaconOpacity: 1,
      zoom: null,
    },
    description: {
      value: '',
    },
    cta: {
      bold: true,
      fontSize: 14,
      italic: false,
      textUnderline: false,
      color: '#ffffff',
      position: 'right',
      backgroundColor: {
        alpha: 100,
        hex: '#9939eb',
      },
      borderColor: '',
    },
    secondary: {
      value: 'CTA',
      bold: true,
      fontSize: 14,
      italic: false,
      textUnderline: false,
      color: '#ffffff',
      position: 'right',
      backgroundColor: {
        alpha: 100,
        hex: '#9939eb',
      },
      borderColor: '',
    },
    version: WIDGET_VERSION,
  },
};

export const POPUP_WIDGET_TEMPLATE: Omit<
  PopupWidget,
  | 'id'
  | 'name'
  | 'sequence'
  | 'localSequence'
  | 'status'
  | 'page_id'
  | 'projectId'
  | 'flowId'
  | 'stream_url'
  | 'stream_status'
  | 'thumbnail_url'
  | 'custom_code'
> = {
  kind: WidgetKind.Popup,
  cta: 'Next',
  audio_url: '',
  options: {
    root: {
      theme: WidgetTheme.Light,
      close_button: false,
      prev_button: false,
      show_step_number: false,
      spot: WidgetBackdrop.Enabled,
      hasSpotlight: false,
      spotlightColor: '#9939eb',
      backgroundColor: DEFAULT_WIDGET_BACKGROUND_COLOR,
      borderColor: DEFAULT_WIDGET_BORDER_COLOR,
      frameWidth: DEFAULT_POPUP_WIDGET_WIDTH,
      secondaryButton: false,
    },
    description: {
      value:
        '<h2 style="text-align: center;"><strong>Explain your CTA</strong></h2><p style="text-align: center;">Describe Call-to-Action details</p>',
    },
    cta: {
      bold: true,
      fontSize: 16,
      italic: false,
      textUnderline: false,
      color: '#ffffff',
      position: 'center',
      backgroundColor: {
        alpha: 100,
        hex: '#9939eb',
      },
      borderColor: '',
    },
    secondary: {
      value: 'CTA',
      bold: true,
      fontSize: 16,
      italic: false,
      textUnderline: false,
      color: '#ffffff',
      position: 'center',
      backgroundColor: {
        alpha: 100,
        hex: '#9939eb',
      },
      borderColor: '',
    },
    js: {
      value: '',
    },
    version: WIDGET_VERSION,
  },
};

export const LEAD_CAPTURE_WIDGET_TEMPLATE: Omit<
  LeadCaptureWidget,
  | 'id'
  | 'projectId'
  | 'flowId'
  | 'name'
  | 'localSequence'
  | 'sequence'
  | 'status'
  | 'dom_id'
  | 'trigger'
  | 'page_id'
  | 'target_id'
  | 'target_type'
  | 'stream_url'
  | 'stream_status'
  | 'thumbnail_url'
> = {
  kind: WidgetKind.LeadCapture,
  cta: 'Submit',
  options: {
    root: {
      backgroundColor: DEFAULT_WIDGET_BACKGROUND_COLOR,
      borderColor: DEFAULT_WIDGET_BORDER_COLOR,
      frameWidth: DEFAULT_POPUP_WIDGET_WIDTH,
      secondaryButton: false,
      close_button: false,
      show_name_field: true,
      prev_button: false,
      show_step_number: false,
      fieldEmailLabel: 'Work Email',
      fieldEmailPlaceholder: 'yourname@acme.com',
      fieldNameLabel: 'Name',
      fieldNamePlaceholder: 'Your Name',
    },
    description: {
      value:
        '<h2 style="text-align: center;"><strong>Get in touch with us</strong></h2>',
    },
    termsAndConditions: {
      value: '<p>By submitting you agree to our terms</p>',
    },
    cta: {
      bold: true,
      fontSize: 16,
      italic: false,
      textUnderline: false,
      color: '#ffffff',
      position: 'center',
      backgroundColor: {
        alpha: 100,
        hex: '#9939eb',
      },
      borderColor: '',
    },
    secondary: {
      value: 'CTA',
      bold: true,
      fontSize: 16,
      italic: false,
      textUnderline: false,
      color: '#ffffff',
      position: 'right',
      backgroundColor: {
        alpha: 100,
        hex: '#9939eb',
      },
      borderColor: '',
    },
    version: WIDGET_VERSION,
  },
  custom_code: '',
};

export const VENDOR_FORM_WIDGET_TEMPLATE: Omit<
  VendorFormWidget,
  | 'id'
  | 'projectId'
  | 'flowId'
  | 'name'
  | 'localSequence'
  | 'sequence'
  | 'status'
  | 'dom_id'
  | 'trigger'
  | 'page_id'
  | 'target_id'
  | 'target_type'
  | 'stream_url'
  | 'stream_status'
  | 'thumbnail_url'
  | 'cta'
> = {
  kind: WidgetKind.VendorForm,
  options: {
    root: {
      frameWidth: DEFAULT_POPUP_WIDGET_WIDTH,
      frameHeight: 400,
      backgroundColor: DEFAULT_WIDGET_BACKGROUND_COLOR,
    },
    version: WIDGET_VERSION,
  },
  custom_code: '',
} as const;

export const VIDEO_CLIP_WIDGET_TEMPLATE = {
  dom_id: '',
  cta: '',
  audio_url: '',
  kind: WidgetKind.VideoClip,
  options: {
    version: WIDGET_VERSION,
    clip: undefined,
  },
} as const;

export const widgetArrowAlignmentMatrix = [
  [
    null,
    WidgetAlignPosition.TopLeft,
    WidgetAlignPosition.TopCenter,
    WidgetAlignPosition.TopRight,
    null,
  ],
  [WidgetAlignPosition.LeftTop, null, null, null, WidgetAlignPosition.RightTop],
  [
    WidgetAlignPosition.LeftCenter,
    null,
    null,
    null,
    WidgetAlignPosition.RightCenter,
  ],
  [
    WidgetAlignPosition.LeftBottom,
    null,
    null,
    null,
    WidgetAlignPosition.RightBottom,
  ],
  [
    null,
    WidgetAlignPosition.BottomLeft,
    WidgetAlignPosition.BottomCenter,
    WidgetAlignPosition.BottomRight,
    null,
  ],
];
export const getWidgetAlignmentIndex = () =>
  widgetArrowAlignmentMatrix.reduce<Record<WidgetAlignPosition, number[]>>(
    (accum, positions: (WidgetAlignPosition | null)[], rowIndex) => {
      positions.forEach((position, columnIndex) => {
        if (position) {
          accum[position] = [rowIndex, columnIndex];
        }
      });
      return accum;
    },
    {} as Record<WidgetAlignPosition, number[]>
  );

export const extLocalVideoFieldName = 'recentlyCapturedVideoUrl';

export enum MediaPosition {
  TopRight = 'top_right',
}
