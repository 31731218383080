import { serialize, CookieSerializeOptions, parse } from 'cookie';

import memoryCookieStorage from './memoryCookieStorage';

const setCookies = (
  key: string,
  value: string,
  options?: CookieSerializeOptions
) => {
  if (typeof document === 'undefined') {
    serialize(key, value, options);
  } else {
    document.cookie = serialize(key, value, options);
  }
};

/**
 * @reference client side
 * https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/cookies.js
 * @returns boolean
 */
const isCookiesAvailable = (): boolean => {
  if (typeof window === 'undefined') {
    // Since we can't directly check cookies server-side in this manner and
    // because cookies are a client-side feature, we might assume they're
    // "available" in the sense that the server is capable of setting them.
    // However, this might not be what you want to assume since this doesn't
    // actually check if the client supports or allows cookies. Consider
    // revising this logic based on our specific needs.
    return true;
  }

  try {
    document.cookie = 'cookietest=1';
    const response = document.cookie.indexOf('cookietest=') !== -1;
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return response;
  } catch (e) {
    return false;
  }
};

const getCookies = (key: string, cookieHeader?: string) => {
  let allCookies: string;

  // client side
  if (typeof document !== 'undefined') {
    allCookies = document.cookie;
  } else {
    // server side
    allCookies = cookieHeader || '';
  }

  const parsedCookies = parse(allCookies);

  const value = parsedCookies[key];

  return value || undefined;
};

const removeCookies = (key: string, path?: string) => {
  setCookies(key, '', { maxAge: -1, path });
};

const clearCookies = (serverCookie?: string) => {
  const allCookies =
    typeof document !== 'undefined' ? document.cookie : serverCookie || '';

  allCookies.split(';').forEach((cookie) => {
    const key = cookie.split('=')[0];
    removeCookies(key);
  });
};

const cookieStorage = {
  getItem: getCookies,
  setItem: setCookies,
  removeItem: removeCookies,
  clear: clearCookies,
};

const cookieStorageWithFallback = isCookiesAvailable()
  ? cookieStorage
  : memoryCookieStorage;

export default cookieStorageWithFallback;
