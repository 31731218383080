import { CSSProperties } from 'react';
import merge from 'lodash-es/merge';
import omit from 'lodash-es/omit';
import get from 'lodash-es/get';

import { DeepPartial } from '../../global';
import isContainHTML from '../../utils/isContainHTML';
import { ProjectKind } from '../project/project.constants';
import {
  DEFAULT_TEXT_COLOR,
  DEFAULT_TEXT_COLOR_INVERTED,
  HOTSPOT_WIDGET_TEMPLATE,
  LEAD_CAPTURE_WIDGET_TEMPLATE,
  POPUP_WIDGET_TEMPLATE,
  TOOLTIP_WIDGET_TEMPLATE,
  VENDOR_FORM_WIDGET_TEMPLATE,
  MEDIA_WIDGET_TEMPLATE,
  getWidgetAlignmentIndex,
  widgetArrowAlignmentMatrix,
  WidgetKind,
  WidgetNeedCalcAlignment,
  DEFAULT_HOTSPOT_WIDGET_DESCRIPTION,
  DEFAULT_WIDGET_PLACEHOLDER,
  VIDEO_CLIP_WIDGET_TEMPLATE,
  DEFAULT_WIDGET_BUTTON_PLACEHOLDER,
  WidgetAlignPosition,
  WidgetBackdrop,
} from './widgets.constants';
import {
  BaseWidget,
  WidgetPresetValues,
  DraftNewWidget,
  HotspotWidget,
  PopupWidget,
  TooltipWidget,
  MediaWidget,
  WidgetBackdropRect,
  WithCta,
  LeadCaptureWidget,
  VendorFormWidget,
  WithSecondary,
  WithTitleV0,
  WithDescriptionV0,
  WidgetOffset,
  WidgetPlacement,
  VideoClipWidget,
  WidgetShapedPresetValues,
  AnyWidget,
} from './widgets.types';
import { pxToRem } from '../ui/ui.utils';
import { ProjectPageId } from '../projectPages/projectPages.types';
import { isLightColor } from '../../utils/isLightColor';

export const isAnchorBasedWidget = (widget: {
  kind: WidgetKind;
}): widget is HotspotWidget | TooltipWidget =>
  isHotspot(widget) || isTooltip(widget);

export const isModalLikeWidget = (
  widget: BaseWidget
): widget is PopupWidget | MediaWidget | LeadCaptureWidget | VendorFormWidget =>
  isMedia(widget) ||
  isPopup(widget) ||
  isLeadCapture(widget) ||
  isVendorForm(widget);

export const isLeadCaptureLikeWidget = (
  widget: BaseWidget
): widget is LeadCaptureWidget | VendorFormWidget =>
  isLeadCapture(widget) || isVendorForm(widget);

export const hasAnchorElement = (widget: BaseWidget): boolean =>
  Boolean(widget?.dom_id);

export const isLeadCapture = (
  widget: BaseWidget
): widget is LeadCaptureWidget => widget.kind === WidgetKind.LeadCapture;

export const isVendorForm = (widget: {
  kind: WidgetKind;
}): widget is VendorFormWidget => widget.kind === WidgetKind.VendorForm;

export const isVideoClip = (widget: {
  kind: WidgetKind;
}): widget is VideoClipWidget => widget.kind === WidgetKind.VideoClip;

export const isMedia = (widget: BaseWidget): widget is MediaWidget =>
  widget.kind === WidgetKind.Media;

export const isPopup = (widget: BaseWidget): widget is PopupWidget =>
  widget.kind === WidgetKind.Popup;

export const isHotspot = (widget: {
  kind: WidgetKind;
}): widget is HotspotWidget => widget.kind === WidgetKind.Hotspot;

export const isTooltip = (widget: {
  kind: WidgetKind;
}): widget is TooltipWidget => widget.kind === WidgetKind.Tooltip;

export const createWidgetConfig = (config: DraftNewWidget): DraftNewWidget => {
  const { kind } = config;
  if (kind === WidgetKind.Media) {
    return merge({}, { ...MEDIA_WIDGET_TEMPLATE }, config);
  }
  if (kind === WidgetKind.Tooltip) {
    return merge({}, { ...TOOLTIP_WIDGET_TEMPLATE }, config);
  }
  if (kind === WidgetKind.Hotspot) {
    return merge({}, { ...HOTSPOT_WIDGET_TEMPLATE }, config);
  }
  if (kind === WidgetKind.Popup) {
    return merge({}, { ...POPUP_WIDGET_TEMPLATE }, config);
  }
  if (kind === WidgetKind.LeadCapture) {
    return merge({}, { ...LEAD_CAPTURE_WIDGET_TEMPLATE }, config);
  }
  if (kind === WidgetKind.VendorForm) {
    return merge({}, { ...VENDOR_FORM_WIDGET_TEMPLATE }, config);
  }
  if (kind === WidgetKind.VideoClip) {
    return merge({}, { ...VIDEO_CLIP_WIDGET_TEMPLATE }, config);
  }
  throw Error(
    `Cannot create widget configuration. Reason: unsupported widget kind - ${kind}`
  );
};

/**
 * @see: https://gitlab.com/storylane-devs/product/-/wikis/Widget-Version
 * @param widget
 * @returns
 */
export const upgradeWidgetVersion = (widget: BaseWidget): BaseWidget => {
  let upgradedWidget = merge({}, widget);

  if (
    typeof upgradedWidget.options?.version === 'undefined' ||
    upgradedWidget.options?.version === 0
  ) {
    upgradedWidget = upgradeWidgetToVersion1(upgradedWidget);
  }

  return upgradedWidget;
};

/**
 * Migrate widget version "0" => "1".
 * Major update is related to "title" and "description" fields.
 * We need to convert old values into compatible with a wysiwyg.
 *
 * @see: https://gitlab.com/storylane-devs/product/-/wikis/Widget-Version#version-1-current
 */
export const upgradeWidgetToVersion1 = (widget: BaseWidget): BaseWidget => {
  const nextWidget = merge({}, widget, { options: { version: 1 } });

  // vendor form doesn't have text fields.
  if (isVendorForm(nextWidget)) return nextWidget;

  let titleHtml = '';
  if (
    !isHotspot(nextWidget) && // if there is a "title",
    !isTooltip(nextWidget) // ignore it for hotspot_v0 and tooltip_v0
  ) {
    titleHtml = getTextFromWidgetV0(nextWidget, 'title'); // get existing "title" with all styles persisted.
  }
  delete nextWidget.options.title; // delete deprecated "title" options

  let descriptionHtml = '';
  if (!isLeadCapture(nextWidget)) {
    // lead_capture_v0 doesn't have description
    descriptionHtml = getTextFromWidgetV0(nextWidget, 'description');
  }
  nextWidget.options.description = {
    value: `${titleHtml}${descriptionHtml}`, // "title" becomes a part of the "description"
  };

  return nextWidget;
};

/**
 * Get "title"|"description" as html-string with all styles persisted as tags/styles.
 *
 * INPUT:
 *  {
 *    title: 'text',
 *    options: {
 *      bold: true,
 *      italic: false,
 *      fontSize: 22,
 *      textUnderline: false,
 *      color: 'red',
 *      textAlignment: 'center'
 *    },
 *  }
 *
 * OUTPUT:
 *  "<h2 style="text-align: center;font-size: 22px;color: red"><strong>text</strong></h2>"
 *
 * @param widget
 * @returns
 */
export const getTextFromWidgetV0 = (
  widget: BaseWidget,
  field: 'title' | 'description'
): string => {
  let value =
    (widget as unknown as { title?: string; description?: string })[field] ||
    '';

  const wrapWithTag = (config: {
    inner: string;
    tag: string;
    styles?: string[];
  }) => {
    const { inner, tag, styles } = config;
    return `<${tag}${
      styles?.length ? ` style="${styles.join(';')}"` : ''
    }>${inner}</${tag}>`;
  };

  // If there is a value, then apply styles as html tags
  if (value) {
    let rootTag = '';
    // if there is any existing markup
    if (isContainHTML(value)) {
      rootTag = 'article';
    } else {
      rootTag = field === 'title' ? 'h2' : 'p';
    }
    if (field in widget.options) {
      // apply deprecated typings to `widget.options.title`.
      const deprecatedTitleOpts:
        | Partial<WithTitleV0['title']>
        | Partial<WithDescriptionV0['description']> = widget.options[field];

      const { textAlignment, textUnderline, bold, italic, color, fontSize } =
        deprecatedTitleOpts;

      if (bold) {
        value = wrapWithTag({ inner: value, tag: 'strong' });
      }
      if (italic) {
        value = wrapWithTag({ inner: value, tag: 'em' });
      }
      if (textUnderline) {
        value = wrapWithTag({ inner: value, tag: 'u' });
      }

      const rootStyles = [];
      if (textAlignment) {
        rootStyles.push(`text-align: ${textAlignment}`);
      }
      if (fontSize) {
        rootStyles.push(`font-size: ${pxToRem(fontSize)}em`);
      }
      if (color) {
        rootStyles.push(`color: ${color}`);
      }
      value = wrapWithTag({
        inner: value,
        tag: rootTag,
        styles: rootStyles,
      });
    } else {
      value = wrapWithTag({ inner: value, tag: rootTag });
    }
  }

  return value;
};

export const calculateWidgetTextColor = (
  bgColor: string,
  textType: 'primary' | 'secondary' = 'primary'
): string => {
  const isBgLight = isLightColor(bgColor);
  switch (textType) {
    case 'primary':
      return isBgLight ? DEFAULT_TEXT_COLOR : '#F3F5F7';
    case 'secondary':
      return isBgLight ? DEFAULT_TEXT_COLOR_INVERTED : '#D9D9DF';
  }
};

export const calculateWidgetCtaBackground = (bg: string): string =>
  isLightColor(bg) ? '#9939EB' : '#FFFFFF';

export const isCtaButtonEnabled = (widget: BaseWidget): boolean =>
  !isVendorForm(widget) &&
  !isVideoClip(widget) &&
  (isModalLikeWidget(widget) || widget.options.root.next_button);

export const isSecondaryButtonEnabled = (widget: BaseWidget): boolean =>
  !isVendorForm(widget) &&
  !isVideoClip(widget) &&
  widget.options.root.secondaryButton;

export const getWidgetButtonStyles = (
  values: WithCta['cta'] | WithSecondary['secondary']
): CSSProperties => {
  return {
    backgroundColor: values.backgroundColor
      ? hexAlphaToRGBA(values.backgroundColor.hex, values.backgroundColor.alpha)
      : undefined,
    fontSize: `${pxToRem(values.fontSize)}em`,
    fontWeight: values.bold ? 500 : undefined,
    fontStyle: values.italic ? 'italic' : undefined,
    textDecoration: values.textUnderline ? 'underline' : undefined,
    color: values.color,
    borderWidth: '0.125em',
    borderStyle: 'solid',
    /**
     * If borderColor equals empty string OR undefined - no border color applied
     */
    borderColor: values.borderColor || 'transparent',
  };
};

export const getWidgetArrowPlacement = (
  alignment: WidgetAlignPosition
): 'top' | 'left' | 'right' | 'bottom' => {
  switch (alignment) {
    case WidgetAlignPosition.TopCenter:
    case WidgetAlignPosition.TopRight:
    case WidgetAlignPosition.TopLeft:
      return 'top';
    case WidgetAlignPosition.BottomCenter:
    case WidgetAlignPosition.BottomRight:
    case WidgetAlignPosition.BottomLeft:
      return 'bottom';
    case WidgetAlignPosition.LeftTop:
    case WidgetAlignPosition.LeftBottom:
    case WidgetAlignPosition.LeftCenter:
      return 'left';
    case WidgetAlignPosition.RightBottom:
    case WidgetAlignPosition.RightCenter:
    case WidgetAlignPosition.RightTop:
      return 'right';
  }

  return 'top';
};

export const getWidgetBackdropSelector = (
  widget: HotspotWidget | TooltipWidget
): string => {
  return widget.options.root.backdropSelector?.[0] || widget.dom_id;
};

export const getWidgetTextPlaceholder = (arg: { kind: WidgetKind }): string => {
  if (isHotspot(arg)) return DEFAULT_HOTSPOT_WIDGET_DESCRIPTION;
  else if (isTooltip(arg)) return DEFAULT_WIDGET_PLACEHOLDER;
  return DEFAULT_WIDGET_BUTTON_PLACEHOLDER;
};

/**
 * `getClockwiseWidgetAlignment` returns "next" widget alignment based on "current" alignment(in a clockwise direction).
 * `alignment` - current alignment.
 *
 * @param alignment
 * @returns
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/21757954/Widget+customization#Rotation Docs}
 */
export const getClockwiseWidgetAlignment = (
  alignment: WidgetAlignPosition | typeof WidgetNeedCalcAlignment
): WidgetAlignPosition | typeof WidgetNeedCalcAlignment => {
  switch (alignment) {
    case WidgetAlignPosition.BottomRight:
    case WidgetAlignPosition.BottomLeft:
    case WidgetAlignPosition.BottomCenter:
      return WidgetAlignPosition.LeftCenter;
    case WidgetAlignPosition.LeftTop:
    case WidgetAlignPosition.LeftCenter:
    case WidgetAlignPosition.LeftBottom:
      return WidgetAlignPosition.TopCenter;
    case WidgetAlignPosition.TopLeft:
    case WidgetAlignPosition.TopCenter:
    case WidgetAlignPosition.TopRight:
      return WidgetAlignPosition.RightCenter;
    case WidgetAlignPosition.RightTop:
    case WidgetAlignPosition.RightCenter:
    case WidgetAlignPosition.RightBottom:
      return WidgetAlignPosition.BottomCenter;
    default:
      return alignment;
  }
};
export function getInitialAlignment(
  clickPercentageCoords: {
    x: number;
    y: number;
  },
  kind: ProjectKind
): WidgetAlignPosition {
  if (kind === ProjectKind.Html) {
    return getInitialAlignmentHTML(clickPercentageCoords);
  } else {
    return getInitialAlignmentImage(clickPercentageCoords);
  }
}

export function getInitialAlignmentImage(clickPercentageCoords: {
  x: number;
  y: number;
}): WidgetAlignPosition {
  const { x, y } = clickPercentageCoords;

  if (x < 20) {
    if (y < 30) {
      return WidgetAlignPosition.LeftTop;
    } else if (y > 70) {
      return WidgetAlignPosition.LeftBottom;
    }
    return WidgetAlignPosition.LeftCenter;
  } else if (x > 80) {
    if (y < 30) {
      return WidgetAlignPosition.RightTop;
    } else if (y > 70) {
      return WidgetAlignPosition.RightBottom;
    }
    return WidgetAlignPosition.RightCenter;
  } else if (y < 50) {
    return WidgetAlignPosition.TopCenter;
  } else if (y >= 50) {
    return WidgetAlignPosition.BottomCenter;
  }

  return WidgetAlignPosition.BottomCenter;
}

interface WidgetClipped {
  top: boolean;
  right: boolean;
  left: boolean;
  bottom: boolean;
}

interface GetArrowWidgetOptimalPlacementArg {
  alignment: WidgetAlignPosition;
  offset: WidgetOffset;
  /**
   * `backdropRect` might be turned off in settings, then it will be `null`
   */
  backdropRect: WidgetBackdropRect | null;
  intersectionEntry: IntersectionObserverEntry;
}
export function getArrowWidgetOptimalPlacement({
  backdropRect,
  offset,
  alignment,
  intersectionEntry,
}: GetArrowWidgetOptimalPlacementArg): WidgetPlacement {
  const {
    boundingClientRect, // Returns the bounds rectangle of the target element.
    intersectionRect, // Returns a DOMRectReadOnly representing the target's visible area.
  } = intersectionEntry;
  const clipped: WidgetClipped = {
    top: Math.round(boundingClientRect.top) < Math.round(intersectionRect.top),
    left:
      Math.round(boundingClientRect.left) < Math.round(intersectionRect.left),
    right:
      Math.round(boundingClientRect.right) > Math.round(intersectionRect.right),
    bottom:
      Math.round(boundingClientRect.bottom) >
      Math.round(intersectionRect.bottom),
  };
  return backdropRect
    ? getOptimalPlacementAroundBackdrop({
        alignment,
        clipped,
        offset,
        backdropRect,
      })
    : {
        offset: offset,
        alignment: getOptimalAlignment(alignment, clipped, offset),
      };
}

interface GetOptimalPlacementAroundBackdropArg {
  backdropRect: WidgetBackdropRect;
  clipped: WidgetClipped;
  alignment: WidgetAlignPosition;
  offset: WidgetOffset;
}
export function getOptimalPlacementAroundBackdrop({
  alignment,
  clipped,
  offset,
  backdropRect,
}: GetOptimalPlacementAroundBackdropArg): {
  offset: WidgetOffset;
  alignment: WidgetAlignPosition;
} {
  let nextAlignment = alignment;
  let nextX = offset.x;
  if (clipped.right) {
    nextX = backdropRect.x;
    nextAlignment = WidgetAlignPosition.RightCenter;
  } else if (clipped.left) {
    nextX = backdropRect.x + backdropRect.width;
    nextAlignment = WidgetAlignPosition.LeftCenter;
  }

  let nextY = offset.y;
  if (clipped.bottom) {
    nextY = backdropRect.y;
    nextX = backdropRect.x + backdropRect.width / 2;
    nextAlignment = WidgetAlignPosition.BottomCenter;
  } else if (clipped.top) {
    nextY = backdropRect.y + backdropRect.height;
    nextX = backdropRect.x + backdropRect.width / 2;
    nextAlignment = WidgetAlignPosition.TopCenter;
  }

  return {
    alignment: nextAlignment,
    offset: {
      x: nextX,
      y: nextY,
    },
  };
}

export function getOptimalAlignment(
  currentAlignment: WidgetAlignPosition,
  clipped: WidgetClipped,
  offset: WidgetOffset
): WidgetAlignPosition {
  const widgetAlignmentIndex = getWidgetAlignmentIndex();
  const [rowIndex, columnIndex] = widgetAlignmentIndex[currentAlignment];

  if (clipped.right) {
    if (columnIndex === widgetArrowAlignmentMatrix[rowIndex].length - 1) {
      return currentAlignment; // already rightmost position
    }

    const nextColumnIndex = columnIndex + 1; // we move arrow to the right side
    if (widgetArrowAlignmentMatrix[rowIndex][nextColumnIndex]) {
      return widgetArrowAlignmentMatrix[rowIndex][
        nextColumnIndex
      ] as WidgetAlignPosition;
    }

    // there is no alignment in a row
    if (rowIndex > 0 && rowIndex < 4) {
      if (offset.y <= 75) {
        return widgetArrowAlignmentMatrix[0][
          nextColumnIndex
        ] as WidgetAlignPosition;
      } else {
        return widgetArrowAlignmentMatrix[
          widgetArrowAlignmentMatrix.length - 1
        ][nextColumnIndex] as WidgetAlignPosition;
      }
    } else if (rowIndex === 0) {
      return widgetArrowAlignmentMatrix[rowIndex + 1][
        nextColumnIndex
      ] as WidgetAlignPosition;
    } else if (rowIndex === 4) {
      return widgetArrowAlignmentMatrix[rowIndex - 1][
        nextColumnIndex
      ] as WidgetAlignPosition;
    }

    throw new Error(
      `There is an error choosing alignment. rowIndex: ${rowIndex}, columnIndex: ${columnIndex}`
    );
  }
  if (clipped.left) {
    if (columnIndex === 0) {
      return currentAlignment; // already leftmost position
    }

    const nextColumnIndex = columnIndex - 1; // we move arrow to the left side
    if (widgetArrowAlignmentMatrix[rowIndex][nextColumnIndex]) {
      return widgetArrowAlignmentMatrix[rowIndex][
        nextColumnIndex
      ] as WidgetAlignPosition;
    }

    // there is no alignment in a row
    if (rowIndex > 0 && rowIndex < 4) {
      if (offset.y <= 75) {
        return widgetArrowAlignmentMatrix[0][
          nextColumnIndex
        ] as WidgetAlignPosition;
      } else {
        return widgetArrowAlignmentMatrix[
          widgetArrowAlignmentMatrix.length - 1
        ][nextColumnIndex] as WidgetAlignPosition;
      }
    } else if (rowIndex === 0) {
      return widgetArrowAlignmentMatrix[rowIndex + 1][
        nextColumnIndex
      ] as WidgetAlignPosition;
    } else if (rowIndex === 4) {
      return widgetArrowAlignmentMatrix[rowIndex - 1][
        nextColumnIndex
      ] as WidgetAlignPosition;
    }

    throw new Error(
      `There is an error choosing alignment. rowIndex: ${rowIndex}, columnIndex: ${columnIndex}`
    );
  }
  if (clipped.top) {
    if (rowIndex === 0) {
      return currentAlignment; // already topmost position
    }

    const nextRowIndex = rowIndex - 1; // we move arrow to the top side
    if (widgetArrowAlignmentMatrix[nextRowIndex][columnIndex]) {
      return widgetArrowAlignmentMatrix[nextRowIndex][
        columnIndex
      ] as WidgetAlignPosition;
    }

    // there is no alignment in a row
    if (columnIndex > 0 && columnIndex < 4) {
      if (offset.x <= 50) {
        return widgetArrowAlignmentMatrix[
          nextRowIndex
        ][0] as WidgetAlignPosition;
      } else {
        return widgetArrowAlignmentMatrix[nextRowIndex][
          widgetArrowAlignmentMatrix[nextRowIndex].length - 1
        ] as WidgetAlignPosition;
      }
    } else if (columnIndex === 0) {
      return widgetArrowAlignmentMatrix[nextRowIndex][
        columnIndex + 1
      ] as WidgetAlignPosition;
    } else if (columnIndex === 4) {
      return widgetArrowAlignmentMatrix[nextRowIndex][
        columnIndex - 1
      ] as WidgetAlignPosition;
    }

    throw new Error(
      `There is an error choosing alignment. rowIndex: ${rowIndex}, columnIndex: ${columnIndex}`
    );
  }
  if (clipped.bottom) {
    if (rowIndex === widgetArrowAlignmentMatrix.length - 1) {
      return currentAlignment; // already bottommost position
    }

    const nextRowIndex = rowIndex + 1; // we move arrow to the bottom side
    if (widgetArrowAlignmentMatrix[nextRowIndex][columnIndex]) {
      return widgetArrowAlignmentMatrix[nextRowIndex][
        columnIndex
      ] as WidgetAlignPosition;
    }

    // there is no alignment in a row
    if (columnIndex > 0 && columnIndex < 4) {
      if (offset.x <= 50) {
        return widgetArrowAlignmentMatrix[
          nextRowIndex
        ][0] as WidgetAlignPosition;
      } else {
        return widgetArrowAlignmentMatrix[nextRowIndex][
          widgetArrowAlignmentMatrix[nextRowIndex].length - 1
        ] as WidgetAlignPosition;
      }
    } else if (columnIndex === 0) {
      return widgetArrowAlignmentMatrix[nextRowIndex][
        columnIndex + 1
      ] as WidgetAlignPosition;
    } else if (columnIndex === 4) {
      return widgetArrowAlignmentMatrix[nextRowIndex][
        columnIndex - 1
      ] as WidgetAlignPosition;
    }

    throw new Error(
      `There is an error choosing alignment. rowIndex: ${rowIndex}, columnIndex: ${columnIndex}`
    );
  }

  return currentAlignment;
}

export function getPlacementKey(placement: WidgetPlacement): string {
  return `${placement.offset.x}${placement.offset.y}${placement.alignment}`;
}

export function getInitialAlignmentHTML(clickPercentageCoords: {
  x: number;
  y: number;
}): WidgetAlignPosition {
  const { x, y } = clickPercentageCoords;

  if (x < 20) {
    return WidgetAlignPosition.RightCenter;
  } else if (x > 80) {
    return WidgetAlignPosition.LeftCenter;
  } else if (y < 50) {
    return WidgetAlignPosition.BottomCenter;
  } else if (y >= 50) {
    return WidgetAlignPosition.TopCenter;
  }

  return WidgetAlignPosition.BottomCenter;
}

interface GetWidgetPlacementIntersectionArg {
  intersectionEntry: IntersectionObserverEntry;
  currentPlacement: WidgetPlacement;
  visitedPlacements: Map<
    string,
    {
      placement: WidgetPlacement;
      intersectionRatio: number;
    }
  >;
  widgetBackdropRect: WidgetBackdropRect | null;
}
/**
 * @see {@link https://storylane.atlassian.net/l/cp/K15hdmdn Widget Auto Placement docs}
 */
export function getWidgetPlacementIntersection({
  intersectionEntry,
  widgetBackdropRect,
  currentPlacement,
  visitedPlacements,
}: GetWidgetPlacementIntersectionArg): {
  placement: WidgetPlacement;
  done: boolean;
  visitedPlacements: Map<
    string,
    {
      placement: WidgetPlacement;
      intersectionRatio: number;
    }
  >;
} {
  const nextVisitedPlacements = new Map([...visitedPlacements.entries()]);

  const currentIntersectionRatio = intersectionEntry.intersectionRatio;
  const currentPlacementKey = getPlacementKey(currentPlacement);
  nextVisitedPlacements.set(currentPlacementKey, {
    placement: currentPlacement,
    intersectionRatio: currentIntersectionRatio,
  });

  const optimalPlacement = getArrowWidgetOptimalPlacement({
    alignment: currentPlacement.alignment,
    offset: currentPlacement.offset,
    intersectionEntry,
    backdropRect: widgetBackdropRect,
  });
  const optimalPlacementKey = getPlacementKey(optimalPlacement);

  if (
    nextVisitedPlacements.has(optimalPlacementKey) ||
    nextVisitedPlacements.size === Object.keys(WidgetAlignPosition).length
  ) {
    // loop through visited placements to find the one with greatest intersectionRatio.
    let maxIntersectionRatio = -1;
    let maxIntersectionRatioPlacement = optimalPlacement;
    for (const {
      intersectionRatio: visitedIntersectionRatio,
      placement: visitedPlacemenet,
    } of nextVisitedPlacements.values()) {
      if (visitedIntersectionRatio > maxIntersectionRatio) {
        maxIntersectionRatio = visitedIntersectionRatio;
        maxIntersectionRatioPlacement = visitedPlacemenet;
      }
    }
    return {
      placement: maxIntersectionRatioPlacement,
      done: true,
      visitedPlacements: nextVisitedPlacements,
    };
  }

  return {
    placement: optimalPlacement,
    visitedPlacements: nextVisitedPlacements,
    done: false,
  };
}

export function calcWidgetBackdropRect(
  backdropRect: WidgetBackdropRect | null,
  offset: WidgetOffset
): WidgetBackdropRect {
  if (backdropRect) {
    return {
      ...backdropRect,
    };
  } else {
    return {
      x: Math.max(offset.x - 5, 0),
      y: Math.max(offset.y - 11, 0),
      width: 10,
      height: 12,
    };
  }
}

export function convertLeadCaptureToVendorForm(
  widget: LeadCaptureWidget,
  diff?: {
    custom_code: string;
  }
): VendorFormWidget {
  const template = createWidgetConfig({
    kind: WidgetKind.VendorForm,
    page_id: widget.page_id,
  }) as DraftNewWidget & { page_id: ProjectPageId };
  return merge(
    template,
    { ...widget, kind: undefined, options: undefined },
    {
      options: {
        root: {
          backgroundColor: widget.options.root.backgroundColor,
          frameWidth: widget.options.root.frameWidth,
        },
      } as VendorFormWidget['options'],
    },
    diff
  );
}

export function convertArbitraryWidgetToVideoClip(
  widget: AnyWidget,
  pageId: string
): VideoClipWidget {
  return merge(
    {
      ...widget,
      kind: undefined,
      dom_id: undefined,
      cta: undefined,
      options: undefined,
      overlay_video_id: null,
      media_duration: null,
      overlay_video: null,
    },
    createWidgetConfig({
      kind: WidgetKind.VideoClip,
      page_id: pageId,
    })
  );
}

/**
 * @see {@link https://storylane.atlassian.net/l/cp/JX6VaeVx Docs}
 * @param widget
 * @param presetValues
 * @param diff
 * @returns
 */
export function convertTooltipToHotspot(
  widget: TooltipWidget,
  presetValues?: DeepPartial<HotspotWidget>,
  diff?: DeepPartial<HotspotWidget>
): HotspotWidget {
  const template = createWidgetConfig({
    kind: WidgetKind.Hotspot,
    page_id: widget.page_id,
    // @ts-expect-error TODO: improve typings `DraftNewWidget`
    options: presetValues?.options,
  });
  return merge(template, omit(widget, ['kind']), diff);
}

/**
 * @see {@link https://storylane.atlassian.net/l/cp/JX6VaeVx Docs}
 * @param widget
 * @param presetValues
 * @param diff
 * @returns
 */
export function convertHotspotToTooltip(
  widget: HotspotWidget,
  presetValues?: DeepPartial<TooltipWidget>,
  diff?: DeepPartial<TooltipWidget>
): TooltipWidget {
  const template = createWidgetConfig({
    kind: WidgetKind.Tooltip,
    page_id: widget.page_id,
    // @ts-expect-error TODO: improve type `DraftNewWidget`
    options: presetValues?.options,
  }) as unknown as TooltipWidget;
  return merge(
    template,
    omit(widget, [
      'kind',
      /**
       * Omit values which are not presented in a `tooltip` widget
       */
      'options.root.beaconColor',
      'options.root.beaconOpacity',
      'options.root.auto_hide_text',
    ]),
    diff
  );
}

/** copies description only if not tooltip <-> hotspot conversion */
export function convertToArbitraryWidget(
  widget: BaseWidget,
  kind: WidgetKind,
  presetValues?: DeepPartial<
    | PopupWidget
    | HotspotWidget
    | TooltipWidget
    | MediaWidget
    | LeadCaptureWidget
  >
): AnyWidget {
  const template = createWidgetConfig({
    kind,
    page_id: widget.page_id,
    // @ts-expect-error TODO: improve type `DraftNewWidget`
    options: presetValues?.options,
  });

  if (template.options?.description) {
    template.options.description.value = widget.options?.description?.value;
  }

  return template as AnyWidget;
}

/**
 * Generate `widget.options` shape and fill it with {@link https://storylane.atlassian.net/l/cp/dTZqYuop "widget configuration preset values"}.
 *
 * @param kind
 * @param values
 *
 * @see {@link https://storylane.atlassian.net/l/cp/dTZqYuop Docs}
 */
export const generateWidgetShapeFromPresetValues = <T extends WidgetKind>(
  kind: T,
  values: WidgetPresetValues
): WidgetShapedPresetValues<T> => {
  const shape: WidgetShapedPresetValues<T> = {
    options: {
      root: {
        show_step_number: values.showStep,
        close_button: values.closeButton,
        prev_button: values.prevButton,
        backgroundColor: values.backgroundColor,
      },
      cta: {
        color: values.ctaColor,
        backgroundColor: values.ctaBackgroundColor,
      },
      secondary: {
        color: values.ctaColor,
        backgroundColor: values.ctaBackgroundColor,
      },
    },
  };

  if (kind === WidgetKind.Hotspot) {
    const { options } = shape as WidgetShapedPresetValues<WidgetKind.Hotspot>;
    options.root.beaconColor = values.beaconColor;
    options.root.beaconOpacity = values.beaconOpacity;
  }

  if (kind === WidgetKind.Hotspot || kind === WidgetKind.Tooltip) {
    shape.options.root.backgroundColor = values.calloutBackgroundColor;
    shape.options.cta.backgroundColor = values.calloutCtaBackgroundColor;
    shape.options.cta.color = values.calloutCtaColor;
    shape.options.secondary.backgroundColor = values.calloutCtaBackgroundColor;
    shape.options.secondary.color = values.calloutCtaColor;
  }

  return shape;
};

/**
 * Retrieve particular values from `widget.options`
 * that can be used as {@link https://storylane.atlassian.net/l/cp/dTZqYuop "widget configuration preset values"}.
 *
 * @param widget
 *
 * @see {@link https://storylane.atlassian.net/l/cp/dTZqYuop Docs}
 */
export const retrievePresetValuesFromWidgetShape = (
  widget: BaseWidget
): WidgetPresetValues => {
  if (
    widget.kind === WidgetKind.Hotspot ||
    widget.kind === WidgetKind.Tooltip
  ) {
    return {
      calloutCtaBackgroundColor: get(widget, 'options.cta.backgroundColor'),
      calloutCtaColor: get(widget, 'options.cta.color'),
      calloutBackgroundColor: get(widget, 'options.root.backgroundColor'),
      beaconColor: isHotspot(widget)
        ? get(widget, 'options.root.beaconColor')
        : undefined,
      beaconOpacity: isHotspot(widget)
        ? get(widget, 'options.root.beaconOpacity')
        : undefined,
    };
  }
  return {
    ctaBackgroundColor: get(widget, 'options.cta.backgroundColor'),
    ctaColor: get(widget, 'options.cta.color'),
    backgroundColor: get(widget, 'options.root.backgroundColor'),
    showStep: get(widget, 'options.root.show_step_number'),
    prevButton: get(widget, 'options.root.prev_button'),
    closeButton: get(widget, 'options.root.close_button'),
  };
};

/**
 * Retrieve `width` and `maxWidth` values from a `widget.options`.
 * @returns
 * @see {@link https://storylane.atlassian.net/l/cp/vNRmgwWE Docs}
 */
export const retrieveWidthStyles = (widget: AnyWidget, unit: 'em' | 'px') => {
  const unitFn = (pxValue: number) =>
    unit === 'em' ? `${pxToRem(pxValue)}em` : `${pxValue}px`;
  if (isAnchorBasedWidget(widget)) {
    const width = widget.options.root.frameWidth
      ? unitFn(widget.options.root.frameWidth)
      : undefined;
    const maxWidth = widget.options.root.maxFrameWidth
      ? unitFn(widget.options.root.maxFrameWidth)
      : undefined;
    return {
      width,
      maxWidth,
    };
  }

  /**
   * For a "dialog" widget — set only `width`.
   * IF one day we need to allow width resize for this group of widgets, THEN copy "anchor-based" widget logic.
   */
  return {
    width: unitFn(widget.options.root.frameWidth),
    maxWidth: undefined,
  };
};

export function displayWidgetKind(kind: WidgetKind) {
  switch (kind) {
    case WidgetKind.Hotspot:
      return 'Hotspot';
    case WidgetKind.Media:
      return 'Media Modal';
    case WidgetKind.Tooltip:
      return 'Tooltip';
    case WidgetKind.LeadCapture:
      return 'Lead Capture';
    case WidgetKind.Popup:
      return 'Text Modal';
    case WidgetKind.VendorForm:
      return 'Vendor Form';
    case WidgetKind.VideoClip:
      return 'Video Clip';
  }
}

function hexAlphaToRGBA(h: any, a: any) {
  // eslint-disable-next-line prefer-const
  let ex = /^#([\da-f]{3}){1,2}$/i;
  if (ex.test(h)) {
    let r: string | number = 0,
      g: string | number = 0,
      b: string | number = 0;

    // 3 digits
    if (h.length == 4) {
      r = '0x' + h[1] + h[1];
      g = '0x' + h[2] + h[2];
      b = '0x' + h[3] + h[3];

      // 6 digits
    } else if (h.length == 7) {
      r = '0x' + h[1] + h[2];
      g = '0x' + h[3] + h[4];
      b = '0x' + h[5] + h[6];
    }
    return `rgba(${Number(r)},${Number(g)},${Number(b)},${Number(a) / 100})`;
  } else {
    return '';
  }
}

export const isAnchorBasedWidgetBackdropEnabled = (
  widget: BaseWidget
): boolean =>
  widget.options.root.spot === WidgetBackdrop.Enabled ||
  widget.options.root.hasSpotlight;
